






















































import { Component, Vue } from 'vue-property-decorator';
import { readPatient } from '@/store/patients/getters';
import { dispatchGetPatients } from '@/store/patients/actions';
import { readPrescriptions, readPrescription } from '@/store/prescriptions/getters';
import { dispatchGetPrescriptions } from '@/store/prescriptions/actions';
import { readProcedures } from '@/store/procedures/getters';
import { dispatchGetProcedures } from '@/store/procedures/actions';
import { readMedication } from '@/store/medications/getters';
import { dispatchGetMedications } from '@/store/medications/actions';

@Component
export default class PatientInfo extends Vue {
  public statusIcons = ['took_.png', 'missed_.png', 'unauthorized_.png'];
  public medicationList = ['All'];

  public async mounted() {
    await dispatchGetPatients(this.$store); // TODO: remove
    await dispatchGetMedications(this.$store); // TODO: remove
    await dispatchGetPrescriptions(this.$store, +this.$router.currentRoute.params.id);
    await dispatchGetProcedures(this.$store, +this.$router.currentRoute.params.id);
  }

  get patient() {
    return readPatient(this.$store)(+this.$router.currentRoute.params.id);
  }

  get prescriptions() {
    return readPrescriptions(this.$store);
  }

  get procedures() {
    return readProcedures(this.$store);
  }

  private getStatusIcon(status: number) {
    return require('@/assets/' + this.statusIcons[status]);
  }

  private getMedicationIcon(medicationId: number) {
    const icon = readMedication(this.$store)(medicationId)?.icon;
    return require('@/assets/medications/' + icon + '.png');
  }

  private getMedicationName(medicationId: number) {
    return readMedication(this.$store)(medicationId)?.name;
  }

  private getMedicationNameForPrescription(prescriptionId: number) {
    const medicationId = readPrescription(this.$store)(prescriptionId)?.medication_id;
    return this.getMedicationName(medicationId!);
  }
}
